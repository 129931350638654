<template>
  <div class="yimiao">
    <div>
      <div style="margin-bottom: 100px;">
        <van-field v-model="datafrom.GuestName"
          v-if="preHotelList.length != 0"
          label="预约订单"
          placeholder="请选择预约订单"
          @click="preHotelPicker = true"
          readonly="readonly"
          clickable />
        <van-popup v-model="preHotelPicker"
          position="bottom">
          <van-picker show-toolbar
            :columns="preHotelList"
            value-key="Name"
            @confirm="preHotelQr"
            @cancel="preHotelPicker = false" />
        </van-popup>

        <van-cell-group>
          <van-field v-model="datafrom.Name"
            required
            label="名称"
            placeholder="请输入名称" />
        </van-cell-group>
        <van-cell-group>
          <van-field v-model="datafrom.IdCard"
            label="身份证号"
            required
            placeholder="请输入身份证号"
            @blur="IdNum(datafrom.IdCard)" />
        </van-cell-group>
        <van-cell-group>
          <van-field v-model="datafrom.Mobile"
            label="手机号"
            placeholder="请输入手机号" />
        </van-cell-group>
        <div class="filtersBox">
          <div class="labelClass"
            style="padding: 10px 6px;">
            <div>
              性别
            </div>
          </div>
          <div style="padding: 10px 14px 6px 5px;">
            <van-radio-group v-model="datafrom.Sex"
              direction="horizontal">
              <van-radio name="1"
                class="radio">男</van-radio>
              <van-radio name="2"
                class="radio">女</van-radio>
            </van-radio-group>
          </div>
        </div>
        <van-field v-model="datafrom.Birthday"
          name="Birthday"
          label="出生日期"
          @click="birthday = true"
          readonly="readonly"
          placeholder="请选择出生日期" />
        <van-popup v-model="birthday"
          position="bottom"
          get-container="body">
          <van-datetime-picker v-model="jiezhognDate"
            show-toolbar
            type="date"
            title="请选择出生日期"
            :min-date="minDate"
            :max-date="maxDate"
            @cancel="birthday = false"
            @confirm="birthdayFend">
          </van-datetime-picker>
        </van-popup>

        <van-field v-model="datafrom.CantonName"
          readonly
          label="地区"
          placeholder="请选择所在地区"
          @click="cantonShow = true" />
        <van-popup v-model="cantonShow"
          round
          position="bottom">
          <van-cascader v-model="cascaderValue"
            title="请选择所在地区"
            :options="NativeCantonCodedata"
            @close="cantonShow = false"
            @finish="onCantonFinish"
            :field-names="fieldNames" />
        </van-popup>
        <van-cell-group>
          <van-field v-model="datafrom.Address"
            label="住址"
            placeholder="请输入住址" />
        </van-cell-group>
        <van-field v-model="datafrom.NationDesc"
          label="民族"
          placeholder="请选择民族"
          @click="shopTypePicker = true"
          readonly="readonly"
          clickable />
        <van-popup v-model="shopTypePicker"
          position="bottom">
          <van-picker show-toolbar
            :columns="eduCodeList"
            value-key="Name"
            @confirm="shopTypeQr"
            @cancel="shopTypePicker = false" />
        </van-popup>

        <van-field v-model="datafrom.InTime"
          name="InTime"
          required
          label="入住时间"
          @click="inDateTime = true"
          readonly="readonly"
          placeholder="请选择入住时间" />
        <van-popup v-model="inDateTime"
          position="bottom"
          get-container="body">
          <van-datetime-picker v-model="currentDate"
            show-toolbar
            type="datetime"
            title="请选择入住时间"
            :min-date="minDate"
            :max-date="maxDate"
            @cancel="inDateTime = false"
            @confirm="onjiezhongFend">
          </van-datetime-picker>
        </van-popup>
        <van-cell-group>
          <van-field v-model="datafrom.LiveDays"
            label="入住天数"
            type="digit"
            required
            placeholder="请输入入住天数" />
        </van-cell-group>
        <van-cell-group>
          <van-field v-model="datafrom.Remark"
            label="备注"
            placeholder="请输入备注" />
        </van-cell-group>

        <div class="submit"
          style="padding: 10px 7px">
          <span style="color: #646566; font-size: 14px;padding-left: 10px;">照片</span>
        </div>
        <van-uploader v-model="ShopImgList"
          multiple
          class="imgs"
          :max-count="1"
          :after-read="ShopImgRead"
          :before-delete="xcDelete"
          :deletable="true" />

      </div>
      <div style="position: fixed;bottom: 15px;width:100%;">
        <div style="width:100%; text-align: center;">
          <van-button class="button"
            @click="postfrom(1)"
            style="width:95%;border-radius: 5px;"
            type="info">提交</van-button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import { Dialog } from "vant";
import { Toast } from "vant";
import { setOpenId, getOpenId } from "@/utils/auth";
import config from "@/config";
import Compressor from "compressorjs";
import axios from "axios";
import { GetCodeEntrysList } from "@/api/wangge";
import { HGetMyGuest, HSaveGuest } from "@/api/RealInfo";
import { HGetPreHotelPage } from "@/api/shop";
Vue.use(Toast);
import {
  HSaveHotel,
  GetOrgansNullToken,
  WxReBindShop,
} from "@/api/yeguang";
export default {
  components: {
    [Dialog.Component.name]: Dialog.Component,
  },
  data () {
    return {
      // 营业执照
      jkImageList: [],
      //商铺图片
      ShopImgList: [],
      OrganList: [],
      ridlist: [],
      ocodelist: [],
      NativeCantonCodedata: [],
      minDate: new Date(1945, 0),
      maxDate: new Date(2025, 0),
      birthday: false,
      inDateTime: false,
      leaveDateTime: false,
      leaveDate: "",
      jiezhognDate: "",
      idList: {
        organCode: "",
        RId: undefined,
      },
      datafrom: {
        GuestId: 0, //旅客Id
        HotelId: this.$route.params.hotelId,//民宿旅馆Id
        LiveDays: undefined,
        Name: "", // 姓名
        Sex: "", // 性别
        Mobile: "", // 手机号
        IdCard: "", // 身份证号
        NationCode: "", // 民族
        Birthday: "", // 出生日期
        CantonCode: "", // 省市区
        Address: "", // 住址
        InTime: "", // 入住时间
        RoomId: "", // 账号
        Remark: "", // 备注
      },
      eduCodeList: [],
      shopTypePicker: false, //商铺类型
      idListRId: false,
      idListOrg: false,
      addshow: false,
      verifyshow: true,
      OCodeshow: false,
      sexshow: false,
      Nationshow: false,
      yimiao: false,
      datafromBackTime: false,
      datafromOutTime: false,
      dWshow: false,
      bangdingShow: true,
      bjZuoBiao: "",
      shopAdd: this.$route.query.add,
      cascaderValue: '',
      cantonShow: false,
      fieldNames: {
        text: 'label',
        value: 'value',
        children: 'children',
      },
      currentDate: new Date(),
      preHotelPicker: false,
      preHotelList: [],
    };
  },
  created () {
    this.GetOrgansNullToken();
    this.shopXq();
    this.zidianlist();
    this.getPreHotelPage()
    this.datafrom.ShopCate = this.$route.query.shopCate;
  },
  methods: {
    // 获取预约订单
    getPreHotelPage () {
      HGetPreHotelPage({
        openID: getOpenId(),
        hotelId: this.$route.params.hotelId,
        limit: 999
      }).then((res) => {
        if (res.data.code == 0) {
          if (res.data.data.length != 0) {
            for (let i = 0; i < res.data.data.length; i++) {
              const e = res.data.data[i];
              // console.log(e);
              if (e.Status == 1 || e.Status == 2) {
                this.preHotelList.push(e)
              }
            }
          } else {
            this.preHotelList = []
          }

        }
      })
    },
    // 确认订单
    preHotelQr (val) {
      this.datafrom.PreId = val.PreId;
      this.datafrom.GuestName = val.Name;
      this.datafrom.Name = val.Name;
      this.datafrom.IdCard = val.IdCard;
      this.datafrom.Mobile = val.Mobile;
      this.datafrom.InTime = val.InTime;
      if (val.IdCard != '') {
        this.IdNum(val.IdCard)
      }
      this.preHotelPicker = false;
    },
    shopXq: function () {
      console.log(this.$route);
      if (this.$route.params.add == "0") {
        this.datafrom = {
          GuestId: 0, //旅客Id
          HotelId: this.$route.params.hotelId,//民宿旅馆Id
          LiveDays: undefined,
          Name: "", // 姓名
          Sex: "", // 性别
          Mobile: "", // 手机号
          IdCard: "", // 身份证号
          NationCode: "", // 民族
          Birthday: "", // 出生日期
          CantonCode: "", // 省市区
          Address: "", // 住址
          InTime: "", // 入住时间
          RoomId: this.$route.params.roomId, // 房号
          Remark: "", // 备注
        };
      } else if (this.$route.params.add == "1") {
        HGetMyGuest({
          openID: getOpenId(),
          hotelId: this.$route.params.hotelId,
          guestId: this.$route.params.guestId,
        }).then((res) => {
          this.bangdingShow = true;
          console.log(res);
          if (res.data.code == 0) {
            this.datafrom = res.data.data;
            this.datafrom.Sex = res.data.data.Sex + "";
            // console.log(res.data.data);
            //照片
            if (res.data.data.Img != "" && res.data.data.Img != null && res.data.data.Img != 'undefined') {
              this.ShopImgLists = res.data.data.Img.split(',');
              var arr = [];
              for (let i = 0; i < this.ShopImgLists.length; i++) {
                const e = this.ShopImgLists[i];
                arr.push(e);
              }

              this.ShopImgList = arr;
              this.datafrom.Img = res.data.data.Img;
            }
            // this.datafrom.ShopTypeKind = res.data.data.ShopTypeKind;
            this.datafrom.Region = res.data.data.RName;
          }
          if (this.$route.query.add == "3") {
            this.bangdingShow = false;
          }
        });
      }
    },
    dwXuanze: function (row) {
      if (row == "1") {
        this.dWshow = true;
      } else {
        this.dWshow = false;
        this.Coordinate = this.bjZuoBiao;
      }
    },
    // 字典查询
    zidianlist: function () {
      GetCodeEntrysList({ kind: "3" }).then((res) => {
        this.eduCodeList = res.data.data;
      });
      this.GetCityst()
    },
    GetCityst: function () {
      GetCodeEntrysList({ kind: "2" }).then((res) => {
        var Citys = [];
        var Citysx = [];
        var Citysq = [];
        var Citysqp = [];
        var list = res.data.data;
        for (var i = 0; i < list.length; i++) {
          if (list[i].Coding.length == "2") {
            Citys.push({
              value: list[i].Coding,
              children: [],
              label: list[i].Alias,
            });
          } else if (list[i].Coding.length == "4") {
            Citysx.push({
              value: list[i].Coding,
              children: [],
              label: list[i].Alias,
            });
          } else if (list[i].Coding.length == "6") {
            if (list[i].Coding == "410324") {
              Citysq.push({
                value: list[i].Coding,
                label: list[i].Alias,
                children: [],
              });
            } else {
              Citysq.push({ value: list[i].Coding, label: list[i].Alias });
            }
          } else if (list[i].Coding.length > "6") {
            if (list[i].Coding.substr(0, 6) == "410324") {
              Citysqp.push({ value: list[i].Coding, label: list[i].Alias });
            }
          }
        }
        for (var p = 0; p < Citysq.length; p++) {
          if (Citysq[p].value.substr(0, 6) == "410324") {
            Citysq[p].children = Citysqp;
          }
        }
        for (var i = 0; i < Citysq.length; i++) {
          for (var j = 0; j < Citysx.length; j++) {
            if (Citysq[i].value.substr(0, 4) == Citysx[j].value) {
              Citysx[j].children.push(Citysq[i]);
            }
          }
        }
        for (var z = 0; z < Citysx.length; z++) {
          for (var y = 0; y < Citys.length; y++) {
            if (Citysx[z].value.substr(0, 2) == Citys[y].value) {
              Citys[y].children.push(Citysx[z]);
            }
          }
        }
        this.NativeCantonCodedata = Citys;
      });
    },
    // 选中省市区
    onCantonFinish ({ selectedOptions }) {
      this.cantonShow = false;
      this.datafrom.CantonName = selectedOptions.map((option) => option.label).join('/');
      if (selectedOptions[2] == undefined) {
        this.datafrom.CantonCode = selectedOptions[1].value;
      } else {
        this.datafrom.CantonCode = selectedOptions[2].value;
      }
    },
    // 确认民族
    shopTypeQr (val) {
      this.datafrom.NationCode = val.Coding;
      this.datafrom.NationDesc = val.Name;
      this.shopTypePicker = false;
    },
    //上传营业执照
    afterRead (file) {
      var that = this;
      file.status = "uploading";
      file.message = "上传中...";
      new Compressor(file.file, {
        quality: 0.5,
        success (result) {
          var formData = new FormData();
          let c = {
            headers: { "Content-Type": "multipart/form-data" },
          };
          //构造一个 FormData，把后台需要发送的参数添加
          formData.append("file", result, result.name);
          axios.post(config.apiUploadUrl, formData, c).then((res) => {
            if (res.data.code === 0) {
              console.log(res.data.picurl);
              that.datafrom.LicImg = res.data.picurl;
              // that.datafrom.EvalImgs.push(res.data.data);
              file.status = "";
              file.message = "";
            } else {
              Toast.fail(res.data.msg);
            }
          });
        },
      });
    },
    //删除营业执照
    jkDelete (file) {
      this.jkImageList = [];
      this.datafrom.LicImg = "";
    },
    //上传民宿图片
    ShopImgRead (file) {
      var that = this;
      file.status = "uploading";
      file.message = "上传中...";
      new Compressor(file.file, {
        quality: 0.5,
        success (result) {
          var formData = new FormData();
          let c = {
            headers: { "Content-Type": "multipart/form-data" },
          };
          //构造一个 FormData，把后台需要发送的参数添加
          formData.append("file", result, result.name);
          axios.post(config.apiUploadUrl, formData, c).then((res) => {
            if (res.data.code === 0) {
              // console.log(res.data.picurl);
              that.datafrom.Img = res.data.picurl;
              // that.ImgsList.push(res.data.picurl[0]);
              file.status = "";
              file.message = "";
            } else {
              Toast.fail(res.data.msg);
            }
          });
        },
      });
    },
    //删除商铺图片
    xcDelete (file, detail) {
      // this.ShopImgList = [];
      this.datafrom.Img = "";
    },
    // 出生日期
    birthdayFend (val) {
      this.datafrom.Birthday = this.dateformat(val);
      this.birthday = false;
    },
    //入住时间 确认
    onjiezhongFend (val) {
      this.datafrom.InTime = this.dateformatTime(val);
      this.inDateTime = false;
    },
    // 提交
    postfrom: function (row) {
      if (this.datafrom.Name == "") {
        Toast.fail("请输入名称");
        return false;
      }
      // if (this.datafrom.Mobile == "") {
      //   Toast.fail("请输入手机号");
      //   return false;
      // }
      if (this.datafrom.IdCard == "") {
        Toast.fail("请输入身份证号");
        return false;
      }
      if (this.datafrom.LiveDays == "") {
        Toast.fail("请输入入住天数");
        return false;
      }
      if (this.datafrom.InTime == "") {
        Toast.fail("请选择入住时间");
        return false;
      }
      this.datafrom.OpenID = getOpenId();
      this.datafrom.Img = this.datafrom.Img + '';

      HSaveGuest(this.datafrom).then((res) => {
        if (res.data.code == 0) {
          Dialog.alert({
            title: "提示",
            message: "提交成功!",
          }).then(() => {
            this.$router.go(-1);
            // this.$router.push({
            //   name: "register",
            // });
          });
        } else {
          Dialog.alert({
            title: "提示",
            message: "提交失败!" + res.data.msg,
          }).then(() => {
            // on close
          });
        }
      });

    },
    gitocodelist () {
      GetOrgansNullToken().then((res) => {
        this.ocodelist = res.data.data;
      });
    },
    // 社区列表
    GetOrgansNullToken: function (row) {
      GetOrgansNullToken({ level: 4, kind: 1 }).then((res) => {
        this.OrganList = res.data.data;
      });
    },
    fanhui: function () {
      this.$router.go(-1);
    },
    // 社区选择器
    onidListOrg (val) {
      this.datafrom.OCode = val.organcode;
      this.datafrom.OrgName = val.organname;
      this.idListOrg = false;
      this.RegionList(val.organcode);
      this.datafrom.Region = "";
      // this.BuildList(val.RId);
    },
    // 小区(组)选择器
    onidListRId (val) {
      this.datafrom.RId = val.RId;
      this.datafrom.Region = val.Title;
      this.idListRId = false;
      this.BuildList(val.RId);
    },
    // 性别选择器
    onsexshow (val) {
      this.datafrom.sex = val.id;
      this.datafrom.sexName = val.Name;
      this.sexshow = false;
    },

    // 消防器材年检日期
    ondatafromOutTime (val) {
      this.datafrom.YearCheckTime = this.dateformat(val);
      this.datafromOutTime = false;
    },
    // 社区
    onocode (val) {
      console.log(val);
      this.datafrom.OrgName = val.selectedOptions[0].OrganName;
      this.RegionList(val.value);
      this.OCodeshow = false;
    },
    dateformat (val) {
      // 时间格式化事件 yy-mm-dd
      let year = val.getFullYear();
      let month = val.getMonth() + 1;
      let day = val.getDate();
      let houser = val.getHours();
      let Minutes = val.getMinutes();
      if (month >= 1 && month <= 9) {
        month = `0${month}`;
      }
      if (day >= 1 && day <= 9) {
        day = `0${day}`;
      }
      if (houser >= 1 && houser <= 9) {
        houser = `0${houser}`;
      }
      if (Minutes >= 1 && Minutes <= 9) {
        Minutes = `0${Minutes}`;
      }
      // return `${year}-${month}-${day} ${houser}:${Minutes}`;
      return `${year}-${month}-${day}`;
    },
    dateformatTime (val) {
      // 时间格式化事件 yy-mm-dd
      let year = val.getFullYear();
      let month = val.getMonth() + 1;
      let day = val.getDate();
      let houser = val.getHours();
      let Minutes = val.getMinutes();
      if (month >= 1 && month <= 9) {
        month = `0${month}`;
      }
      if (day >= 1 && day <= 9) {
        day = `0${day}`;
      }
      if (houser >= 1 && houser <= 9) {
        houser = `0${houser}`;
      }
      if (Minutes >= 1 && Minutes <= 9) {
        Minutes = `0${Minutes}`;
      }
      return `${year}-${month}-${day} ${houser}:${Minutes}`;
      // return `${year}-${month}-${day}`;
    },
    // 获取身份证
    IdNum (value) {
      // console.log(value);
      var reg = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/;
      if (value.length > 0) {
        if (reg.test(value)) {
          this.go(value.length);
        } else {
          Toast.fail("身份证号码不正确");
        }
      }
    },
    go (val) {
      var iden =
        this.datafrom.IdCard == ""
          ? this.datafrom.IdCard
          : this.datafrom.IdCard;
      // var iden = this.dataForms.IdNumber;
      console.log(iden);
      var id = iden.substring(0, 6);
      // this.datafrom.NativeCantonCode = id;
      // console.log(this.datafrom.NativeCantonCode);
      var sex = null;
      var birth = null;
      var myDate = new Date();
      var month = myDate.getMonth() + 1;
      var day = myDate.getDate();
      var age = 0;
      if (val === 18) {
        age = myDate.getFullYear() - iden.substring(6, 10) - 1;
        sex = iden.substring(16, 17);
        birth =
          iden.substring(6, 10) +
          "-" +
          iden.substring(10, 12) +
          "-" +
          iden.substring(12, 14);
        if (
          iden.substring(10, 12) < month ||
          (iden.substring(10, 12) == month && iden.substring(12, 14) <= day)
        )
          age++;
      }
      if (val === 15) {
        age = myDate.getFullYear() - iden.substring(6, 8) - 1901;
        sex = iden.substring(13, 14);
        birth =
          "19" +
          iden.substring(6, 8) +
          "-" +
          iden.substring(8, 10) +
          "-" +
          iden.substring(10, 12);
        if (
          iden.substring(8, 10) < month ||
          (iden.substring(8, 10) == month && iden.substring(10, 12) <= day)
        )
          age++;
      }
      if (sex % 2 == 0) {
        sex = 2;
        this.datafrom.Sex = sex + "";
        this.age = age;
        this.datafrom.Birthday = birth;
      } else {
        sex = 1;
        // this.hzlist.Sex = sex;
        this.datafrom.Sex = sex + "";
        this.age = age;
        this.datafrom.Birthday = birth;
      }
    },
  },
};
</script>
<style>
.imgs {
  margin: 0 15px;
}

.yimiao .inputFrom {
  margin: 15px 8%;
}

.yimiao .inputFrom input {
  width: 80%;
  margin: 8px 8%;
  padding: 8px 5px;
  font-size: 14px;
  border: 1px solid #e6e6e6;
}

.yimiao .buttons {
  text-align: center;
}

.yimiao .buttons button {
  width: 90%;
  margin: 25px 5%;
  border-radius: 10px;
}

.buttons {
  text-align: center;
}

.buttons button {
  margin: 25px 10px;
  border-radius: 10px;
}
.labelClass {
  font-size: 14px;
  font-weight: 500;
  width: 86px;
  color: #646566 !important;
}
.radio {
  font-size: 14px;
  color: #646566 !important;
}
.filtersBox {
  display: flex;
  border-bottom: 1px solid #ebedf085;
  margin: 0px 10px;
}
</style>